<template>
    <div class="row flex-center min-vh-100 py-6">
        <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div v-if="$route.params.authError" class="alert alert-danger alert-dismissible" role="alert">
                <i class="fas fa-exclamation-circle mr-1" /> An authentication errror ocurred.
                <button class="close" type="button" data-dismiss="alert" aria-label="Close">
                    <span class="font-weight-light" aria-hidden="true">×</span>
                </button>
            </div>
            <div class="card bg-card-gradient text-white text-center">
                <div class="card-body p-5">
                    <img src="/img/logo_white.svg" height="55px" class="mb-4">
                    <p class="opacity-75">
                        Authentication is required to access this page. Please sign in to continue.
                    </p>
                    <button class="btn btn-light btn-block mt-6" @click="$auth.login()">Log in</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
    };
</script>
